/* Global Styles */
:root {
  --primary-color: #333333;
  --secondary-color: #666666;
  --accent-color: #c5a491;
  --background-color: #ffffff;
  --text-color: #333333;
  --heading-font: 'Cinzel', serif;
  --body-font: 'Poppins', sans-serif;
}

/* Override Bootstrap margin */
.mb-3 {
  margin-bottom: 0rem !important;
}

body {
  font-family: var(--body-font);
  color: var(--text-color);
  line-height: 1.6;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font);
  font-weight: 600;
  color: var(--primary-color);
}

a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--golden-primary);
}

.btn {
  font-family: var(--body-font);
  font-weight: 500;
  padding: 12px 30px;
  border-radius: 0;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  color: white;
}

.btn-primary:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.padding-large {
  padding: 100px 0;
}

.padding-medium {
  padding: 60px 0;
}

.padding-small {
  padding: 30px 0;
}

/* Container Styles */
.container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 768px) {
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .padding-large {
    padding: 60px 0;
  }
  
  .padding-medium {
    padding: 40px 0;
  }
  
  .padding-small {
    padding: 20px 0;
  }
}

/* Preloader */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 50px;
  height: 50px;
  border: 3px solid var(--accent-color);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  animation: loader 2s linear infinite;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: var(--accent-color);
  animation: loader-inner 2s linear infinite;
}

@keyframes loader {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes loader-inner {
  0% { height: 0%; }
  25% { height: 0%; }
  50% { height: 100%; }
  75% { height: 100%; }
  100% { height: 0%; }
} 