.typing-effect-container {
  position: absolute;
  bottom: 35px;
  left: 25px;
  z-index: 2;
  width: 100%;
  max-width: 700px;
  padding: 0;
}

.typing-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.static-text {
  font-family: monospace;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
  padding-left: 5px;
}

.highlight-container {
  display: flex;
  align-items: baseline;
  min-height: 70px;
  white-space: nowrap;
}

.highlight {
  color: #5a980b;
  font-size: 3.0rem;
  font-weight: 600;
  line-height: 1.4;
  padding-left: 5px;
  display: inline;
  text-decoration: underline;
  text-decoration-color: #5a980b;
  text-underline-offset: 8px;
}

.static-solutions {
  color: #fff;
  font-size: 3.0rem;
  font-weight: 600;
  line-height: 1.4;
  margin-left: 0;
  display: inline;
}

.typed-cursor {
  display: inline;
  font-size: 3.0rem;
  color: #5a980b;
  line-height: 1.4;
  margin: 0;
  vertical-align: baseline;
}

@keyframes typedjsBlink {
  50% {
    opacity: 0.0;
  }
}

@media (max-width: 768px) {
  .typing-effect-container {
    bottom: 80px;
    left: 30px;
    padding: 0;
  }

  .static-text {
    font-size: 1.4rem;
  }
  
  .highlight, .static-solutions {
    font-size: 1.6rem;
  }

  .highlight-container {
    min-height: 40px;
  }

  .typed-cursor {
    font-size: 1.6rem;
  }
}