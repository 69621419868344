.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  height: 60px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.navbar {
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.brand-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  padding: 2px 0;
  overflow: visible;
  position: relative;
  margin-right: 1rem;
}

.navbar-logo {
  height: 80px;
  width: auto;
  object-fit: contain;
  transition: transform 0.3s ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.brand-container:hover .navbar-logo {
  transform: translateY(-50%) scale(1.02);
}

.navbar-brand img.logo {
  display: none;
}

.navbar .nav-link {
  line-height: 1.2;
  padding: 0.7rem 1.2rem !important;
}

.nav-link {
  font-family: var(--heading-font);
  font-weight: 500;
  color: var(--primary-color) !important;
  padding: 0.35rem 0.9rem !important;
  transition: all 0.3s ease;
  border-radius: 20px;
  margin: 0 0.2rem;
  background-color: transparent;
  border: 1.5px solid transparent;
  font-size: 0.9rem;
  position: relative;
}

.nav-link:hover,
.nav-link:focus,
.nav-link.active {
  color: var(--primary-color) !important;
  background-color: rgba(45, 106, 79, 0.1);
  border-color: var(--primary-color);
}

.nav-link.active {
  background-color: rgba(45, 106, 79, 0.15);
  border-color: var(--primary-color);
  font-weight: 600;
}

.nav-link.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  background-color: var(--primary-color);
  border-radius: 50%;
  opacity: 1;
  transition: all 0.3s ease;
}

@media (max-width: 991px) {
  .site-header {
    height: 50px;
  }
  
  .navbar {
    padding: 0 0.8rem;
  }
  
  .navbar-logo {
    height: 55px;
  }
  
  .user-items {
    margin-top: 1rem;
    padding-left: 0 !important;
    justify-content: flex-start;
  }
  
  .nav-link {
    padding: 0.5rem 1rem !important;
    font-size: 0.95rem;
  }
  
  .dropdown-menu {
    background: transparent;
    box-shadow: none;
    padding-left: 1rem;
  }
  
  .dropdown-item {
    padding: 0.5rem 1rem;
  }

  .dropdown-item:hover {
    transform: translateX(0);
  }

  .nav-link.active::after {
    left: 10px;
    bottom: 50%;
    transform: translateY(50%);
  }
}

.user-items {
  display: flex;
  align-items: center;
}

.user-items button {
  background: none;
  border: none;
  padding: 0.5rem;
  color: var(--primary-color);
  transition: color 0.3s ease;
}

.user-items button:hover {
  color: var(--accent-color);
}

/* Search Box */
.search-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: rgba(255, 255, 255, 0.98);
  z-index: 1001;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.search-box.show {
  height: 100%;
  opacity: 1;
  visibility: visible;
}

.search-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.search-input {
  width: 100%;
  max-width: 600px;
  border: none;
  border-bottom: 2px solid var(--primary-color);
  font-size: 1.5rem;
  padding: 1rem 3rem 1rem 1rem;
  background: transparent;
}

.search-input:focus {
  outline: none;
}

.close-button {
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 2rem;
  background: none;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: var(--accent-color);
}

/* Mobile Navigation */
.navbar-toggler {
  border: none;
  padding: 0;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.offcanvas {
  background-color: var(--background-color);
}

.offcanvas-header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.offcanvas-body {
  padding: 1.5rem;
}

/* Solutions Dropdown Styles */
#solutions-dropdown {
  color: var(--primary-color) !important;
}

.dropdown-menu {
  border-radius: 8px;
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  min-width: 200px;
  margin-top: 0.5rem;
  background: rgba(255, 255, 255, 0.95);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}

.dropdown-item {
  padding: 0.75rem 1rem;
  color: var(--primary-color);
  border-radius: 6px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.dropdown-item:hover {
  background-color: rgba(184, 142, 47, 0.1);
  color: #b88e2f;
  transform: translateX(5px);
}

.dropdown-item .fa-icon {
  margin-right: 0.5rem;
  width: 20px;
  color: #b88e2f;
} 