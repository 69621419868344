.footer {
  background-color: #121212 !important;
  color: white;
  padding-top: 1rem;
  margin-top: 1rem;
}

.footer a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: white;
}

.footer-menu {
  margin-bottom: 2rem;
}

.widget-title {
  color: #40916c;
  font-family: var(--heading-font);
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  position: relative;
}

.widget-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 40px;
  height: 2px;
  background-color: #40916c;
}

.footer-link {
  background: none;
  border: none;
  color: #fff;
  padding: 0;
  font-size: 1rem;
  text-align: left;
  transition: color 0.3s ease, transform 0.3s ease;
  opacity: 0.8;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.footer-link:hover {
  color: white;
  transform: translateX(5px);
  opacity: 1;
}

.footer-menu ul {
  margin: 0;
  padding: 0;
}

.footer-menu ul li {
  margin-bottom: 0.8rem;
}

.contact-info li {
  margin-bottom: 1rem;
  opacity: 0.8;
  line-height: 1.6;
}

.contact-info li.address {
  font-size: 0.9rem;
  line-height: 1.8;
}

.social-icons {
  display: flex;
  gap: 1.5rem;
  margin-top: 1rem;
}

.social-icon {
  color: #fff;
  font-size: 1.2rem;
  transition: all 0.8s ease;
  opacity: 0.8;
  width: 45px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.social-icon:hover {
  color: #fff !important;
  opacity: 1;
  transform: scale(1.20);
}

.newsletter h6 {
  color: white;
  margin-bottom: 1rem;
}

.newsletter .input-group {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.newsletter .form-control {
  background-color: transparent;
  border: none;
  color: white;
  padding: 0.75rem 1rem;
}

.newsletter .form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.newsletter .form-control:focus {
  box-shadow: none;
}

.newsletter .btn {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  color: white;
  padding: 0.75rem 1.5rem;
}

.newsletter .btn:hover {
  background-color: white;
  border-color: white;
  color: var(--primary-color);
}

.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 2rem;
}

.footer-bottom p {
  opacity: 0.7;
  font-size: 0.9rem;
}

@media (max-width: 991px) {
  .footer {
    padding-top: 3rem;
  }

  .footer-menu {
    margin-bottom: 2rem;
  }

  .widget-title {
    margin-bottom: 1.2rem;
  }

  .social-icons {
    margin-top: 0.5rem;
  }
}

@media (max-width: 768px) {
  .footer {
    padding-top: 2rem;
  }

  .footer-menu {
    margin-bottom: 2rem;
  }

  .contact-info li.address {
    font-size: 0.85rem;
  }

  .social-icons {
    justify-content: flex-start;
  }
}

.app-store-icons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 1.5rem;
}

.store-icon {
  display: flex;
  align-items: center;
  gap: 12px;
  color: white;
  text-decoration: none;
  padding: 10px 16px;
  border-radius: 8px;
  transition: all 0.3s ease;
  font-size: 1rem;
  font-weight: 500;
  min-width: 160px;
}

.store-icon:first-child {
  background: linear-gradient(135deg, #00c4cc 0%, #7d2ae8 100%);
}

.store-icon:last-child {
  background: linear-gradient(135deg, #2196f3 0%, #0d47a1 100%);
}

.store-icon:hover {
  color: white;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  filter: brightness(1.1);
}

.store-icon svg {
  font-size: 1.5rem;
}

@media (max-width: 991px) {
  .app-store-icons {
    margin-top: 1.2rem;
  }
  
  .store-icon {
    padding: 8px 14px;
    font-size: 0.95rem;
  }
  
  .store-icon svg {
    font-size: 1.3rem;
  }
} 